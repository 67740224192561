// CSS Files
import '../css/f-landing/f-landing.scss';

// JS Files
const $ = require('jquery');
Window.prototype.$ = $; //HERE IS MY SOLUTION (Without this line it doesn't work!)

import '@popperjs/core';
import 'admin-lte/plugins/bootstrap/js/bootstrap';
import 'slick-carousel';

$(document).ready(function () {
    $('.landing_carousel').on('init', function (slick) {
        $('.landing_carousel').attr('style', 'display: block;');
        $('.landing_logo_desktop').attr('style', 'display: block;');
        $('.spinner_landing').attr('style', 'display: none;');
    });

    /*=======================================
    Iniciamos el carousel de la landing page.
    =======================================*/
    $('.landing_carousel').slick({
        mobileFirst: true,
        arrows: false,
        dots: true,
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        autoplay: true,
        autoplaySpeed: 10000,
    });
});